import React, { useState } from 'react'
import { useAuthProvider } from '../providers/auth'
import { useServiceProvider } from '../providers/service'
import { useTranslation } from "react-i18next";

function RecipeMenuButton({ recipeData, index }) {
	const { t } = useTranslation();
	// Proveedor de autenticación
	const authProvider = useAuthProvider()
	const isAuthenticated = authProvider.checkAuth()
	const [menuOpen, setMenuOpen] = useState(false)
	const [isInWatchLater, setIsInWatchLater] = useState(authProvider.recipeIsInWatchLater(recipeData))

	// Proveedor de servicios
	const serviceProvider = useServiceProvider()

	return isAuthenticated && (
		<div className={`ichef--opciones transition mb-0 top-${index}`}>
			<i className={`opciones transition mb-0`} onClick={() => setMenuOpen(!menuOpen)}/>
			{menuOpen && (
				<div className="sub--menu">
					<a
						href="/#" onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
							serviceProvider.foldersToRecipeLightBoxOn()
							serviceProvider.setFoldersToRecipeLightBoxData(recipeData)
						}}
					>
						{t("add_to_folder")}
					</a>
					<a href="/#" onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						if (authProvider.recipeIsInWatchLater(recipeData)) {
							authProvider.removeFromWatchLater(recipeData)
							setIsInWatchLater(false)
						} else {
							authProvider.addToWatchLater(recipeData)
							setIsInWatchLater(true)
						}
					}}>
						{t("view_later")}{isInWatchLater ? '-' : '+'}
					</a>
					{/*recipeData.variantes && recipeData.variantes.idReceta && (
						<HashLink smooth to={`/receta/${recipeData.variantes.idReceta}/${slugify(recipeData.variantes.titulo || "")}`}>
							{recipeData.variantes.descripcion ? recipeData.variantes.descripcion : (recipeData.variantes.titulo || "").substr(0, 25)}
						</HashLink>
					)*/}
				</div>
			)}
		</div>
	)
}

export default RecipeMenuButton
