import { useEffect, useState } from 'react'
import { useDataProvider } from '../../providers/data'
import { useAuthProvider } from '../../providers/auth'
import { useServiceProvider } from '../../providers/service'
import { PRIVILEGIOS,tienePermisoDe } from '../../providers/utils/functions'
import { useTranslation } from "react-i18next";

function FoldersToRecipeForm() {
	const { t } = useTranslation();
	//Proveedor de datos
	const dataProvider = useDataProvider()

	//Proveedor de servicios
	const serviceProvider = useServiceProvider()

	//Proveedor de autenticación
	const authProvider = useAuthProvider()
	const folders = authProvider.getFolders()
	const userData = authProvider.getIdentity()

	const [recipeData, setRecipeData] = useState({})
	const [newFolderFormEnable, setNewFolderFormEnable] = useState(false)
	const { EDITAR_CARPETAS_PUBLICAS } = PRIVILEGIOS

	useEffect(() => {
		setRecipeData(serviceProvider.getFoldersToRecipeLightBoxData())
	}, [serviceProvider])

	useEffect(() => {
		const folderCheck = document.querySelectorAll('input[name="folderId[]"]')
		folderCheck.forEach((folderItem) => {
			const currFolder = folders.find(folder => folder.idCarpeta === parseInt(folderItem.value))
			if (currFolder && currFolder.recipes && currFolder.recipes.includes(recipeData.idReceta)) {
				folderItem.checked = true
			}
		})
	}, [recipeData.idReceta, folders])

	const handleForm = async (e) => {
		e.preventDefault()
		const form = e.target
		const formData = new FormData(form)
		const folderIds = formData.getAll('folderId[]')
		const count = formData.get('count')
		var newFolder = false

		try {
			// Remove recipe if folder was unchecked
			for (const folder of folders) {
				// If recipe was in folder and folder is no longer checked
				if (folder.isPublic && !tienePermisoDe(EDITAR_CARPETAS_PUBLICAS, userData)){
					continue;
				}

				if (folder.recipes && folder.recipes.includes(recipeData.idReceta) && !folderIds.includes(folder.idCarpeta.toString())) {
					const response = await dataProvider.delete(`api/carpetas/${folder.idCarpeta}?idReceta=${recipeData.idReceta}`, {
						headers: {
							Authorization: userData.jwt,
						},
					})
					if (response.status === 200) {
						folder.recipes = folder.recipes.filter(recipeId => recipeId !== recipeData.idReceta)
					}
				}
			}

			// Add recipe to list of already created folders
			const response = await dataProvider.put(`api/carpetas?idReceta=${recipeData.idReceta}&count=${count}`, {
				data: folderIds,
				headers: {
					Authorization: userData.jwt,
				},
			})
			if (response.status === 200) {
				for (const folderId of folderIds) {
					const currFolder = folders.find(folder => folder.idCarpeta === parseInt(folderId))
					if (currFolder) {
						if (!currFolder.recipes)
							currFolder.recipes = []
						if (!currFolder.recipes.includes(recipeData.idReceta))
							currFolder.recipes.push(recipeData.idReceta)
					}
				}
			}

			if (formData.get("name")) {
				// Create new folder
				const response = await dataProvider.create('api/carpetas', {
					data: {
						nombre: formData.get('name')
					},
					headers: {
						Authorization: userData.jwt,
					},
				})
				// Add recipe to new folder
				if (response.status === 200) {
					newFolder = response.data
					const { status } = await dataProvider.put(`api/carpetas/${newFolder.idCarpeta}?idReceta=${recipeData.idReceta}&count=${count}`, {
						headers: {
							Authorization: userData.jwt,
						},
					})
					if (status === 200) {
						newFolder.recipes = [recipeData.idReceta]
					}
				}
			}
		} catch (e) {
			console.error(e)
		} finally {
			if (newFolder)
				authProvider.updateFolders([...folders, newFolder])
			else
				authProvider.updateFolders(folders)
			serviceProvider.foldersToRecipeLightBoxOff()
		}
	}

	return (
		<form className="form-ichef" onClick={(e) => e.stopPropagation()} onSubmit={handleForm}>
			<h5>{recipeData.titulo}</h5>
			<p>{t("save_to")}</p>

			<section className="sec--check">
				{folders.length > 0 &&
					folders
						.filter(itemFolder => !itemFolder.isPublic || 
							(itemFolder.isPublic && tienePermisoDe(EDITAR_CARPETAS_PUBLICAS, userData)))
						.map((itemFolder, index) => (
							<div key={index}>
								<input
									id={`carpeta-${itemFolder.idCarpeta}`}
									name="folderId[]"
									type="checkbox"
									defaultValue={itemFolder.idCarpeta}
								/>
								<label htmlFor={`carpeta-${itemFolder.idCarpeta}`} className="check">
									{itemFolder.nombre}
								</label>
							</div>
						))}
			</section>

			<section>
				<label>Multiplicador</label>
				<input type="number" name="count" defaultValue="1" min="0" max="100" step="1" />
			</section>

			{!newFolderFormEnable && (
				<section>
					<button>{t("confirm")}</button>
				</section>
			)}

			<section className="nueva">
				{!newFolderFormEnable && (
					<a
						href="/#"
						className="tit-carpeta"
						onClick={(e) => {
							e.preventDefault()
							setNewFolderFormEnable(true)
						}}
					>
						{t("new_folder")}
					</a>
				)}

				{newFolderFormEnable && (
					<>
						<h5 className="tit-carpeta" onClick={() => setNewFolderFormEnable(false)}>
							{t("new_folder")}
						</h5>
						<span>{t("folder_name")}</span>
						<input name="name" placeholder={t("enter_name")} type="text" />
						<section>
							<button>{t("confirm")}</button>
						</section>
					</>
				)}
			</section>
		</form>
	)
}

export default FoldersToRecipeForm
