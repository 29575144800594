import React from "react";
import { useState, useEffect } from "react";

function ShoppingRow({ peso, producto }) {
	const [amountToPurchase, setAmountToPurchase] = useState(producto.amountToPurchase);
	const [haveAmount, setHaveAmount] = useState(0);
	const [haveAll, setHaveAll] = useState(false);
	const [remaining, setRemaining] = useState(0);

	useEffect(() => {
		const difference = haveAll ? 0 : producto.presentacion * (haveAmount + amountToPurchase) - peso;
		setRemaining(difference);
	}, [peso, producto.presentacion, haveAll, haveAmount, amountToPurchase]);

	return (
		<>
			<td>
				{producto.descripcion}
			</td>
			<td>{producto.marca}</td>
			<td>{producto.localVenta.nombre}</td>
			<td>
				<input
					className="listInput"
					type="number"
					min={0}
					value={haveAmount}
					onChange={event => {
						const value = Number(event.target.value);
						setHaveAmount(value);
						const amount = producto.amountToPurchase - value;
						setAmountToPurchase(amount > 0 ? amount : 0);
					}}
				/>
				<span className="side-by-side">
				<input type="checkbox" checked={haveAll} onChange={event => {
					const checked = event.target.checked;
					setHaveAll(checked);
					if (checked) {
						setHaveAmount(producto.amountToPurchase);
						setAmountToPurchase(0);
					} else {
						setHaveAmount(0);
						setAmountToPurchase(producto.amountToPurchase);
					}
				}}/>
				<label>Tengo</label>
				</span>
			</td>
			<td>
				<input
					className="listInput"
					type="number"
					min={0}
					value={amountToPurchase}
					onChange={event => {
						setAmountToPurchase(event.target.value);
					}}
				/>
			</td>
			<td>{remaining >= 0 ? `${remaining}g`: "Insuficiente"}</td>
		</>
	);
}

export default ShoppingRow;
