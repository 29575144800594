import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import BeatLoader from 'react-spinners/BeatLoader';
import { useLocation, useNavigate } from "react-router-dom";
import { useDataProvider } from "../providers/data";
import { PRIVILEGIOS, tienePermisoDe } from '../providers/utils/functions'
import { FORMTYPE,STATUS_SERVER } from "../providers/utils/functions";
import validator from 'validator';
import { useAuthProvider } from '../providers/auth';
import Dialog from '../components/Dialog'

function ActivateAccount() {
    const { t } = useTranslation();
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const navigate = useNavigate();
    //const token = searchParams.get("token");
    const [token, setToken] = useState(searchParams.get("token"));
    const authProvider = useAuthProvider();

    const [tokenLoading, setTokenLoading] = useState(true);
    const [tokenActivated, setTokenActivated] = useState(false);

    const [userOrEmail, setUserOrEmail] = useState('');
    const [tokenSent, setTokenSent] = useState(false);
	const [userFormType, setUserFormType] = useState(null)

    const [serverMsg, setServerMsg] = useState({ status: null, msg: "", show: false });

	//Proveedor de datos
	const localApiDataProvider = useDataProvider('localApi')
	const dataProvider = useDataProvider()

	//Proveedor de servicios
    const { EDITAR_CARPETAS_PUBLICAS } = PRIVILEGIOS


    async function GetPublicFolders() {
		try {
			const response = await dataProvider.getList('api/carpetas', {
				headers: {
					'Content-Type': 'application/json',
					'Accept': '*/*',
				}
			});
			return response.data;
		} catch (error) {
			return [];
		}
	}

	async function GetUserFolders(jwt) {
		try {
			const response = await dataProvider.getList('api/carpetas', {
				headers: {
					Authorization: jwt,
					'Content-Type': 'application/json',
					'Accept': '*/*',
				}
			});
			return response.data;
		} catch (error) {
			return [];
		}
	}

    async function GetRecipes(idCarpeta, jwt) {
		try {
			const response = await dataProvider.getList(`api/carpetas/${idCarpeta}`, {
				headers: {
					...(jwt && { Authorization: jwt }),
					'Content-Type': 'application/json',
					'Accept': '*/*',
				}, data: {
				}
			});
			return response.data;
		} catch (error) {
			return [];
		}
	}

    const handleLogin = async (e) => {
		e.preventDefault()

		authProvider
			.login(null,null,token)
			.then(async (userAuthenticated) => {
				try {
					const { data, status } = await localApiDataProvider.getFavoriteRecipes({
						headers: {
							'Content-Type': 'application/json',
							Accept: 'application/json, text/plain, */*',
							Authorization: userAuthenticated.jwt,
						},
						params: {
							userId: userAuthenticated.idUsuario,
						},
					})

					if (status === 200) {
						authProvider.updateFavorites(data.map((recipe) => recipe.idReceta))
						authProvider.updateLikes(
							data.filter((recipe) => recipe.likes === 1).map((recipe) => Number(recipe.idReceta))
						)
					}

					try {
						// Fetch public and user folders concurrently
						const [publicFolders, userFolders] = await Promise.all([
							GetPublicFolders(),
							GetUserFolders(userAuthenticated.jwt),
						])

						// Fetch recipes for both public and user folders concurrently
						const [publicFolderRecipes, userFolderRecipes] = await Promise.all([
							Promise.all(publicFolders.map(folder => GetRecipes(folder.idCarpeta, null))),
							Promise.all(userFolders.map(folder => GetRecipes(folder.idCarpeta, userAuthenticated.jwt))),
						])

						// Assign the recipes to their respective folders
						publicFolders.forEach((folder, index) => {
							folder.readOnly = !tienePermisoDe(EDITAR_CARPETAS_PUBLICAS, userAuthenticated)
							folder.cssClass = !tienePermisoDe(EDITAR_CARPETAS_PUBLICAS, userAuthenticated)  ? 'fixed': 'public'
							folder.recipes = publicFolderRecipes[index].map(recipe => recipe.idReceta);
						})

						userFolders.forEach((folder, index) => {
							folder.recipes = userFolderRecipes[index].map(recipe => recipe.idReceta);
						})

						// Remove duplicated folders
						const filterUserFolders = userFolders.filter(f1 => !publicFolders.some(f2 => f1.idCarpeta === f2.idCarpeta))

						// Combine and set all folders
						authProvider.updateFolders(publicFolders.concat(filterUserFolders))
					} catch (error) {
						console.error(error)
					}

					setServerMsg({ status: null, msg: '', show: false })
				} catch (e) {
					setServerMsg({ status: null, msg: '', show: false })
				}

				navigate('/')
			
			})
			.catch((error) => {
				setServerMsg({ status: STATUS_SERVER.ERROR, msg: error, show: true })
			})
	}
    
    const handleTokenVerification = async () => {
        try {
            const response = await authProvider.verifyTokenActivation(token);
            const { status, data } = response || {};
            const { token: verifiedToken } = data || {};
            
            if (status === 200 && verifiedToken) {
                setServerMsg({
                    status: STATUS_SERVER.SUCCESS,
                    msg: t('account_activated_msg'),
                    show: true,
                });
                setTokenActivated(true);
                setTokenLoading(false);
            } else {
                setTokenLoading(false);
            }
        } catch (error) {
            var errorMessage=error.data?.message || error.error || error.message;
            var isExpired = error.data?.expired || false;
            if (isExpired) {
                setUserFormType(FORMTYPE.RESEND_ACTIVATE_TOKEN)
                errorMessage=t('expired_activation_token');

            }
            
            setServerMsg({ status: STATUS_SERVER.ERROR, msg: errorMessage, show: true });
            setTokenLoading(false);
        }
    };

    const handleResetActivation = async (e) => {
        e.preventDefault();
        const isEmail = validator.isEmail(userOrEmail);
        try {
            const response = await authProvider.resetActivation(!isEmail && userOrEmail, isEmail && userOrEmail);
            const { status } = response || {};
            
            if (status === 200) {
                setServerMsg({
                    status: STATUS_SERVER.SUCCESS,
                    msg: t('resend_activation_token'),
                    show: true,
                });
                setTokenSent(true);
                setUserOrEmail('');
            }
        } catch (error) {
            setServerMsg({ status: STATUS_SERVER.ERROR, msg: error.message, show: true });
        }
    };

    // Verifica el token al cargar la página
    useEffect(() => {
        if (token && tokenLoading) {
            handleTokenVerification();
        }else if(tokenLoading){
            setUserFormType(FORMTYPE.RESEND_ACTIVATE_TOKEN);
            setTokenLoading(false);
        }
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    return (
        <form action="" className="transition form-activation">
            {tokenLoading ? (
                <div className="sweet-loading mt-5 pt-5">
                    <BeatLoader color={"#509F2C"} loading={tokenLoading} size={20} />
                </div>
            ) : userFormType===FORMTYPE.ACTIVATE_ACCOUNT ? (
                <div className="activation-container">

            <header className="text-center">
				<h6>
                    {t("activate_account")}
					
				</h6>
			</header>

			<div>
				<input
					type="text"
					name="token"
					placeholder="Token"
					onChange={(e) => setToken(e.target.value)}
					value={token}
					required
				/>
			</div>
			<button className="transition" name="submit" onClick={(e) => handleTokenVerification(e)}>
				{t("verify")}
			</button>
			<footer>
			
				<a
					href="/#"
					onClick={(event) => {
						event.preventDefault();
						setUserFormType(FORMTYPE.RESEND_ACTIVATE_TOKEN)
						setServerMsg({ status: null, msg: '', show: false })
					}}
				>
					{t("request_new_token")}
				</a>
				
			</footer>

			{serverMsg && serverMsg.show ? (
				<div
					className={`msj ${
						serverMsg.status === STATUS_SERVER.ERROR
							? 'error'
							: serverMsg.status === STATUS_SERVER.SUCCESS
							? 'ok'
							: ''
					}`}
				>
					{serverMsg.msg}
				</div>
			) : (
				<></>
			)}
            </div>

            ) : userFormType===FORMTYPE.RESEND_ACTIVATE_TOKEN  ? (
                <div className="activation-container">
                    <header className="text-center">
					<h6>{t("request_new_token")}</h6>
				</header>
                {serverMsg.show && (
                        <div
                            className={`msj ${
                                serverMsg.status === STATUS_SERVER.ERROR
                                    ? 'error'
                                    : serverMsg.status === STATUS_SERVER.SUCCESS
                                    ? 'ok'
                                    : ''
                            }`}
                        >
                            {serverMsg.msg}
                        </div>
                    )}
                    <div>
                    <input
                        type="text"
                        name="user"
                        placeholder={t("user_or_email")}
                        onChange={(e) => setUserOrEmail(e.target.value)}
                        value={userOrEmail}
                        required
                    />
                    <button className="transition" name="submit" onClick={(e) => handleResetActivation(e)}>
                        {t("request")}
                    </button>
                    <footer>
				
				<a
					href="/#"
					onClick={(event) => {
						event.preventDefault();
						setUserFormType(FORMTYPE.ACTIVATE_ACCOUNT)
						setServerMsg({ status: null, msg: '', show: false })
					}}
				>
					{tokenSent ? t("activate_account") : t("already_have_token")}
				</a>
			</footer>
                    </div>
                  
                </div>
            ) :  (
                <div>
               { <Dialog
				buttons={
					<>
						<button className="btn btn-primary btn-enviar" onClick={(e) =>{ if(tokenActivated){ handleLogin(e);}else{navigate('/');}}}>
							{t("confirm")}
						</button>
                    
					</>
				}
			>
								<div
						className={`msj-dlg ${
							serverMsg.status === STATUS_SERVER.ERROR ? "error" : serverMsg.status === STATUS_SERVER.SUCCESS ? "ok" : ""
						}`}
					>
						{serverMsg.msg}
					</div><div></div>
			</Dialog>}
                    {/*serverMsg.show && (
                        <div
                            className={`msj ${
                                serverMsg.status === STATUS_SERVER.ERROR
                                    ? 'error'
                                    : serverMsg.status === STATUS_SERVER.SUCCESS
                                    ? 'ok'
                                    : ''
                            }`}
                        >
                            {serverMsg.msg}
                        </div>
                    )*/}
                </div>
            )}
        </form>
    );
}

export default ActivateAccount;
